import React from "react";
import { Route, Switch } from "react-router-dom";
import RouteGuard, { RenderDashboard } from "RouteGuard";
import Join from "components/Join";
import Login from "components/Login";
import Password from "components/Password";
import PricingTable from "components/PricingTable";
import ResetPass from "components/ResetPass";
import ServiceModeBanner from "components/ServiceModeBanner";
import CheckYourEmail from "components/common/CheckYourEmail";
import Confirm from "components/email/Confirm";
import RegisterBasic from "components/register/RegisterBasic";
import RegisterClient from "components/register/RegisterClient";
import RegisterInvited from "components/register/RegisterInvited";
import RegisterPremierUser from "components/register/RegisterPremierUser";
import ImpersonationOptions from "components/delegatedUser/ImpersonationOptions";
import NotFound from "components/NotFound";
import allRoutes from "routes";
import Home from "components/Home";

const AppRouter = () => {
  return (
    <>
      <ServiceModeBanner />
      <Switch>
        {allRoutes.map((route, i) => (
          <RouteGuard key={route.path + i} {...route}>
            <Route {...route} />
          </RouteGuard>
        ))}
        <Route path={"/"} exact render={() => <Home />} />
        <Route path={`/password`} component={Password} />
        <Route path={`/reset/:token`} component={ResetPass} />
        <Route path={`/register`} exact component={Join} />
        <Route path={`/join-platform/:token`} component={RegisterInvited} />
        <Route path={`/rep/premier`} component={RegisterPremierUser} />
        <Route path={`/client`} exact component={RegisterClient} />
        <Route path={`/rep/basic`} component={RegisterBasic} />
        <Route path={"/check/email"} component={CheckYourEmail} />
        <Route path={`/pricing`} component={PricingTable} />
        <Route path={`/confirm/:token`} component={Confirm} />
        <Route path={`/login`} component={Login} />
        <Route path={`/impersonation-options`} component={ImpersonationOptions} />
        <Route
          path="*"
          component={() => (
            <RenderDashboard>
              <NotFound />
            </RenderDashboard>
          )}
        />
      </Switch>
    </>
  );
};

export default AppRouter;
