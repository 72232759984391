import React from "react";
import "./css/redesign-components.css";
import "./css/hiw-section.css";
import ReactPlayer from "react-player";
interface HowItWorksSectionProps {}

const videoUrl = "https://myrepapp-assets.s3.us-west-1.amazonaws.com/myrep-v2.mp4";

const HowItWorksSection: React.FC<HowItWorksSectionProps> = () => {
  return (
    <div className="section-container">
      <div className="hiw-top-container">
        <div className="description-pill-btn">{`How it works?`}</div>
        <p className="section-heading">
          Join the MyRep Community of
          <br /> Innovative Practices
        </p>
      </div>
      <div className="video-player-container">
        <ReactPlayer url={videoUrl} playing={false} controls={true} width="100%" height="auto" />
      </div>
      <button className="header-btn btn-standard" style={{ alignSelf: "center", padding: 0 }}>
        <a
          rel="noopener noreferrer"
          href="https://app.smartsheet.com/b/form/7059ea5fd4ca4aa89c50dd3fa48ae911"
          target="_blank"
          style={{ color: "#fff", padding: "10px 20px" }}
        >
          Request a Demo
        </a>
      </button>
    </div>
  );
};

export default HowItWorksSection;
