import React, { useEffect, useRef, useState } from "react";
import "./css/redesign-components.css";
import "./css/bannerSection.css";
import { SlCalender } from "react-icons/sl";
import { CiBellOn } from "react-icons/ci";
import { CiClock1 } from "react-icons/ci";
import { IconType } from "react-icons";
import { useHistory } from "react-router-dom";

interface BannerSectionProps {}

interface BannerItem {
  title: string;
  description: string;
  icon: IconType;
}
const BANNER_ITEM_DATA = [
  {
    icon: SlCalender,
    title: "Easy to use",
    description: "Our user-friendly interface ensures that you can navigate the platform effortlessly",
  },
  {
    icon: CiBellOn,
    title: "Notifications",
    description: "Never miss a critical opportunity",
  },
  {
    icon: CiClock1,
    title: "Real-time Updates",
    description: "Access real-time updates to make informed decisions quickly and efficiently",
  },
];
const BannerSection: React.FC<BannerSectionProps> = () => {
  const history = useHistory();
  const [boxSize, setBoxSize] = useState({ width: 270, height: 270 });
  const [boxCount, setBoxCount] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const calculateBoxes = () => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight;

      const minBoxSize = 200;
      const boxWidth = Math.max(minBoxSize, Math.floor(containerWidth / Math.floor(containerWidth / minBoxSize)));
      const boxHeight = boxWidth;

      const boxesPerRow = Math.floor(containerWidth / boxWidth);
      const boxesPerColumn = Math.floor(containerHeight / boxHeight);
      const totalBoxes = boxesPerRow * boxesPerColumn;

      setBoxSize({ width: boxWidth, height: boxHeight });
      setBoxCount(totalBoxes);
    }
  };

  useEffect(() => {
    calculateBoxes();
    window.addEventListener("resize", calculateBoxes);
    return () => window.removeEventListener("resize", calculateBoxes);
  }, []);

  const getBannerItem = (itemData: BannerItem, index: number) => {
    const IconComponent = itemData.icon;
    return (
      <div className="banner-item-container" key={index}>
        <div className="icon-container">
          <IconComponent fontWeight={"bold"} />
        </div>

        <span className="item-title">{itemData.title}</span>
        <span className="item-description">{itemData.description}</span>
      </div>
    );
  };
  return (
    <div className="section-container banner-container">
      <div
        className="boxes-container"
        ref={containerRef}
        style={{
          gridTemplateColumns: `repeat(auto-fill, minmax(${boxSize.width}px, 1fr))`,
        }}
      >
        {[...Array(boxCount)].map((_, index) => (
          <div
            className="bg-box"
            key={index}
            style={{
              width: `${boxSize.width}px`,
              height: `${boxSize.height}px`,
            }}
          />
        ))}
      </div>
      <div className="heading-items-container">
        <p className="scheduling-heading">
          Scheduling for{" "}
          <span className="scheduling-sub-content">
            medical <br /> practices and representatives
          </span>
        </p>
        <p className="heading-subtext">
          MyRep provides medical practices with an easy-to-use platform for automated rep scheduling, notifications, and
          <br />
          sample requests. It improves office efficiency by eliminating stressful, time-consuming and ineffective manual
          <br />
          processes so office staff can focus more on patient care.
        </p>
        <div className="horizontal-container">
          <button className="header-btn btn-standard" onClick={() => history.push("/register")}>
            Get Started
          </button>
          <button className="header-btn btn-outlined" style={{ padding: 0 }}>
            <a
              rel="noopener noreferrer"
              href="https://app.smartsheet.com/b/form/7059ea5fd4ca4aa89c50dd3fa48ae911"
              target="_blank"
              style={{ color: "#25aae1", padding: "10px 20px" }}
            >
              Request a Demo
            </a>
          </button>
        </div>
      </div>
      <div className="horizontal-container">{BANNER_ITEM_DATA.map((item, index) => getBannerItem(item, index))}</div>
    </div>
  );
};

export default BannerSection;
