import React from "react";
import "./css/redesign-components.css";
import "./css/schedule-section.css";
import appointments from "../../assets/svg/home/schedule/appointments.svg";
import support from "../../assets/svg/home/schedule/support.svg";
import updates from "../../assets/svg/home/schedule/updates.svg";
import flexibility from "../../assets/svg/home/schedule/flexibility.svg";
interface ScheduleSectionProps {}

interface promoItem {
  image: string;
  title: string;
  description: string;
  backgroundColor: string;
}
const SCHEDULE_PROMO_ITEMS = [
  {
    image: appointments,
    title: "Expedited Appointments",
    description:
      "While other platforms offer appointments scheduled a full calendar year in advance, MyRep ensures that opportunities are always available within the next two months.",
    backgroundColor: "rgba(237, 250, 250, 1)",
  },
  {
    image: support,
    title: "Flexible Territory Management ",
    description:
      "Whether you’re currently out-of-area or managing a large territory, MyRep can be used to schedule appointments from anywhere",
    backgroundColor: "#F9F9F9",
  },
  {
    image: updates,
    title: "Real-time Updates",
    description:
      "With MyRep Premier, reps receive instant notifications for new appointment requests or cancellations. This allows them to quickly schedule appointments and share vital information with healthcare providers, maximizing their reach and strengthening valuable relationships",
    backgroundColor: "#F9F9F9",
  },
  {
    image: flexibility,
    title: "Added Flexibility",
    description:
      "MyRep Premier empowers you to transfer appointments to other Premier reps if your availability changes. Unlike other platforms that restrict transfers to internal colleagues, MyRep allows you to easily transfer appointments across companies, giving you greater flexibility and opportunity.",
    backgroundColor: "rgba(237, 250, 250, 1)",
  },
];
const ScheduleSection: React.FC<ScheduleSectionProps> = () => {
  const getPromoItem = (itemData: promoItem, index: number) => {
    return (
      <div key={index} style={{ backgroundColor: itemData.backgroundColor }} className="promo-item">
        <img src={itemData.image} alt={`${itemData.title} icon`} className="promo-item-image" />
        <p className="promo-item-title">{itemData.title}</p>
        <p className="promo-item-description">{itemData.description}</p>
      </div>
    );
  };
  return (
    <div className="section-container">
      <div className="top-container">
        <div className="description-pill-btn">{`Benefits for Representatives`}</div>
        <p className="section-heading">
          Connect with Key Offices in the MyRep Community
          <br /> That Matter Most to You
        </p>
        <p className="section-sub-heading">Maximize Your Impact in the Field</p>
      </div>
      <div className="promo-items-container">
        {SCHEDULE_PROMO_ITEMS.map((elem: promoItem, index: number) => getPromoItem(elem, index))}
      </div>
    </div>
  );
};

export default ScheduleSection;
