import moment from "moment";
import toast from "react-hot-toast";

const DATE_FORMAT: string = "M/D/YY";
const APPOINTMENT_DAY_FORMAT: string = "MMM Do, YYYY";
const TIME_FORMAT: string = "h:mma";
const WEEK_DAY: string = "dddd";

const getDisplayDate = (date: string) => {
  return moment(date).format("M/D/YY");
};

const makeToast = (message: string, type: string, time: number = 3000) => {
  const t = type === "success" ? toast.success : type === "error" ? toast.error : toast;
  t(message, {
    duration: time,
    position: "top-center",
  });
};

const HOME_SECTIONS = {
  benefits: "benefitsRef",
  howItWorks: "howItWorksRef",
  testimonial: "testimonialsRef",
  faq: "faqRef",
};
export { DATE_FORMAT, WEEK_DAY, getDisplayDate, TIME_FORMAT, makeToast, APPOINTMENT_DAY_FORMAT, HOME_SECTIONS };
